<template>
  <div>
    <label
        :for="formname + '-upload'"
        @dragover="e => e.preventDefault()"
        @dragleave="e => e.preventDefault()"
        @drop="handleDrop($event)"
    >
      <span v-if="!nolabel" class="block mb-1 text-sm font-sans font-medium text-bzr-grey">
        {{ label }}<span v-if="required" class="text-darksalmon">*</span>
      </span>
      <div
          v-if="newFiles.length === 0"
          ref="uploader"
          @keydown.prevent.enter="$refs.uploader.click()"
          tabindex="0"
          class="sm:px-4 px-2 py-4 sm:py-6 text-bzr-grey hover:text-bzr-dark bg-white cursor-pointer transition-all text-center outline-2 outline-dashed outline-bzr-grey/50 !outline-offset-0 hover:!outline-bzr-dark focus-visible:!outline-2 focus-visible:!outline-dashed focus-visible:!outline-bzr-dark rounded-lg group"
      >
        <DocumentPlusIcon class="w-10 h-10 mx-auto mb-3"/>
        <p class="hidden sm:block">
          Přetáhněte soubory<br/>nebo
          <span class="text-bzr-secondary font-bold group-focus:underline group-hover:underline">klikněte pro vybrání</span>
        </p>
        <p class="sm:hidden">
          <span class="text-sm">Vybrat soubory</span>
        </p>
      </div>
    </label>

    <input
        :id="formname + '-upload'"
        :name="formname + '-upload'"
        type="file"
        accept="application/pdf,image/png,image/jpg,image/jpeg,image/jfif,image/webp,image/heic,image/heif"
        multiple
        :value="value"
        @click="value = null"
        @change="handleFile($event)"
        class="hidden"
    />

    <div v-for="(file, index) in newFiles" :key="'asdasd/' + index"
        :class="file.error ? 'outline-bzr-primary outline-dashed' : 'outline-bzr-grey/50 hover:outline-bzr-dark outline'"
         class="relative flex justify-between w-full p-2 my-3 outline-2 transition-all duration-100 ease-linear rounded-md">
      <div class="w-min px-2 flex value-col items-end justify-center">
        <span v-if="file.error === 'loadingHeic'">
          <div class="w-full h-full bg-white/75 rounded flex justify-center items-center">
            <div class="animate-spin inline-block w-6 h-6 border-4 border-tertiary border-t-transparent rounded-full" role="status">
              <span class="hidden">Loading...</span>
            </div>
          </div>
        </span>
        <ExclamationCircleIcon v-else-if="file.error" class="w-6 h-6 text-darksalmon"/>
        <template v-else-if="file.type.includes('image')">
          <PhotoIcon v-if="!file.base" class="w-6 h-6"/>
          <div v-else class="w-6 h-6 flex justify-center items-center">
            <img class="object-contain" :src="file.base"/>
          </div>
        </template>
        <DocumentIcon v-else class="w-6 h-6"/>
      </div>
      <div :class="file.error ? 'w-11/12' : 'w-4/5'">
        <p class="text-base font-medium text-bzr-dark truncate">
          {{ file.name }}
        </p>
        <p class="text-sm text-grey truncate">
          <span v-if="file.error === 'loadingHeic'">
            Konvertuji na formát JPG
          </span>
          <span v-else-if="file.error === 'format'">
            Nepodporovaný formát
          </span>
          <span v-else-if="file.error === 'size'">
            Přesažen limit 64MB na soubor
          </span>
          <span v-else>
            {{ kilobytes(file.size) }}
          </span>
        </p>
      </div>
      <div v-if="!file.error" class="w-min pr-2 flex flex-col items-end justify-center">
        <TrashConfirm 
            :non-interactive="!!file.error"
            confirm-text="Odebrat soubor?"
            @delete="removeFile(index)" 
        />
      </div>
    </div>

    <label
        :for="formname + '-upload'"
        @dragover="e => e.preventDefault()"
        @dragleave="e => e.preventDefault()"
        @drop="handleDrop($event)"
        v-if="newFiles.length !== 0"
    >
      <div
          ref="uploader"
          @keydown.prevent.enter="$refs.uploader.click()"
          tabindex="0"
          class="xl:mx-16 mx-8 mt-3 p-2 text-bzr-grey hover:text-bzr-dark flex flex-row justify-center items-center space-x-3 hover:underline cursor-pointer outline-2 outline-dashed outline-bzr-grey/50 hover:outline-bzr-dark rounded-lg transition-colors ease-in-out"
      >
        <DocumentPlusIcon class="w-6 h-6"/>
        <span>
          Přidejte soubory
        </span>
      </div>
    </label>

    <span v-if="invalid" class="block mt-1 text-sm font-medium text-darksalmon">
      {{ invalid }}
    </span>
  </div>
</template>

<script>
import heic2any from '@tuily/heic2any'
import {kilobytes} from '@/helpers/index'
import {filePath} from '@/api/index'
import TrashConfirm from '@/components/TrashConfirm.vue'
import {ExclamationCircleIcon, PhotoIcon, DocumentIcon, DocumentPlusIcon} from '@heroicons/vue/24/solid'

export default {
  name: "FormsFileUpload",
  components: {
    TrashConfirm,
    PhotoIcon,
    DocumentIcon,
    DocumentPlusIcon,
    ExclamationCircleIcon
  },
  props: {
    modelValue: {
      required: true,
      type: [FormData, Array]
    },
    label: {
      default: "Input text",
      type: String
    },
    invalid: {
      default: '',
      type: String
    },
    formname: {
      default: "",
      type: String,
      required: true
    },
    nolabel: {
      default: false,
      type: Boolean
    },
    required: {
      default: true,
      type: Boolean
    }
  },
  emits: ['update:modelValue', 'validate'],
  data() {
    return {
      value: null,
      newFiles: [],

      sizeLimit: 64 * 1024 * 1024 // 64MB
    }
  },
  methods: {
    filePath,
    kilobytes,
    handleFile(event) {
      console.log('handle file event', event);
      if(this.newFiles.length > 0) {
        this.newFiles.push(...Array.from(event.target.files))
      } else {
        this.newFiles = Array.from(event.target.files) // FileList is read-only, create generic Array
      }
      // console.log('files', this.newFiles);
      this.prepareFiles()
    },
    handleDrop(event) {
      console.log('drop file event', event);
      event.preventDefault()
      if(this.newFiles.length > 0) {
        this.newFiles.push(...Array.from(event.dataTransfer.files))
      } else {
        this.newFiles = Array.from(event.dataTransfer.files)
      }
      this.prepareFiles()
    },

    async prepareFiles() {
      console.log('prepare files');
      this.$emit('update:modelValue', [])
      console.log('empty modelValue');

      let formData = new FormData()
      const allowed_extensions = [
        "pdf", "png", "jpg", "jpeg", "jfif", "webp", "heic", "heif",
        "PDF", "PNG", "JPG", "JPEG", "JFIF", "WEBP", "HEIC", "HEIF"
      ];

      for(let i = 0; i < this.newFiles.length; i++) {
        let extension = this.newFiles[i].name.split('.').pop();
        // console.log(extension, allowed_extensions);

        // HEIC files are not supported by browsers yet, convert to JPG please
        if (["heic", "heif", "HEIC", "HEIF"].includes(extension))
          this.newFiles[i].error = 'loadingHeic'

        if (this.newFiles[i].size >= this.sizeLimit)
          this.newFiles[i].error = 'size'

        if (!allowed_extensions.includes(extension))
          this.newFiles[i].error = 'format'
      }

      function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      }

      for(let i = 0; i < this.newFiles.length; i++) {
        const extension = this.newFiles[i].name.split('.').pop();
        // console.log(extension, allowed_extensions);

        try {
          this.newFiles[i].base = await blobToBase64(this.newFiles[i])
        } catch (e) {
          console.error(e);
        }

        // HEIC files are not supported by browsers yet, convert to JPG please
        if (["heic", "heif", "HEIC", "HEIF"].includes(extension)) {
          const newFilename = this.newFiles[i].name.replace(extension, 'jpg')
          const jpegBlob = await heic2any({
            blob: this.newFiles[i],
            toType: "image/jpeg",
            quality: 1
          })
          this.newFiles[i] = new File([jpegBlob], newFilename, {type: "image/jpeg"})
          this.newFiles[i].error = undefined
        }

        if (!this.newFiles[i].error)
          formData.append('files', this.newFiles[i], this.newFiles[i].name)
      }

      console.log('processed form data', formData);
      let isFormDataEmpty = true
      for (let p of formData) {
        isFormDataEmpty = false
        break
      }
      console.log('object is empty', isFormDataEmpty);

      this.$emit('update:modelValue', isFormDataEmpty ? [] : formData)
      this.$emit('validate')
    },
    removeFile(index) {
      console.log('removign file');
      this.newFiles.splice(index, 1)
      this.prepareFiles()
    }
  }
}
</script>
