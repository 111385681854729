import axios from 'axios'
import { defineStore } from 'pinia'


export default defineStore('global', {
  state: () => ({
    token: '',
    user: null,

    params: {
      email: '',
      document: '',
      token: '',
      redoFlag: '',
      mobileFlag: '',
      requestId: '',
      mode: 'OWNEST', // 'BZR'
    },
  }),

  getters: {
    hasToken: (state) => !!state.token,
    hasUser: (state) => !!state.user,

    modeBZR: (state) => state.params.mode === 'BZR',
  },

  actions: {


    consumeQuery(query) {
      this.params.email = query.e
      this.params.document = query.d
      this.params.token = query.t
      this.params.redoFlag = query?.r
      this.params.mobileFlag = query?.m === '1' 
      this.params.requestId = query?.req
      this.params.mode = query?.mode ?? 'OWNEST'
    },


  }
})
