<template>
  <div>
    <span class="block mb-1 text-grey font-medium">
      {{ label }}<span v-if="required" class="text-darksalmon">*</span>
    </span>

    <div
      :class="horizontal ? 'flex flex-row space-x-2 justify-between items-center pb-1' : 'flex flex-col space-y-3'"
      class="pt-2 w-full"
      style="grid-auto-rows: 1fr;"
    >

      <div
        v-for="item in items"
        :key="item.value"
        :class="{'w-full': horizontal}"
        class="rounded-sm outline-1 outline-offset-0 focus-visible:outline-dark"
        tabindex="0"
      >
        <div
          :class="[
              modelValue === item.value ? 'text-bzr-primary !border-bzr-primary' : 'text-bzr-dark',
              horizontal ? 'h-full px-2 py-3 rounded text-center flex flex-row justify-center items-center' : 'p-4 rounded-lg']"
          class="px-3 font-medium uppercase text-center rounded-md transition-all ease-in-out select-none cursor-pointer bg-white border border-bzr-grey/50 hover:border-bzr-dark"
          @click="propagate(item.value)"
        >
          <span>
            {{ item.label }}
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'FormsRadio',
  props: {
    modelValue: {
      default: '',
      required: true
    },
    items: {
      default: new Array(),
      type: Array,
      required: true
    },
    label: {
      default: 'Select value',
      type: String
    },
    horizontal: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      buffer: null
    }
  },
  methods: {
    propagate(val) {
      this.$emit('update:modelValue', val)
      this.$nextTick(() => {
        this.$emit('validate')
      })
    }
  }
}
</script>
