<template>
  <router-view></router-view>
</template>

<script>
export default {
  async created() {
    await this.$recaptchaLoaded()
    this.$recaptchaInstance.value.hideBadge()
  }
}
</script>
