<template>

  <div class="relative px-6 py-3 focus-within:z-10"
       :class="{
        'rounded-xl rounded-b-none border border-gray-300 !border-b-0': firstInFieldset,
        'border border-gray-300 border-b-0': !firstInFieldset,
        'rounded-xl rounded-t-none': lastInFieldset, 'bg-palesalmon': invalid, ' border-b': invalid && lastInFieldset,
        'bg-gray-100': disabled,
        '!border !rounded-xl': firstInFieldset && lastInFieldset,
        'hidden': innerType === 'hidden'
      }">
    <label :for="formname" class="block pb-0.5 text-sm font-bold text-grey flex justify-between"
           :class="{'!text-gray-400': disabled, 'text-darksalmon': invalid}">
      <span>{{label}}<span v-if="required && !disabled" class="text-darksalmon">*</span></span>
    </label>
    <div v-if="!nolabel && invalid"  class="-mt-1">
      <span class="-mt-1 p-0 m-0 text-sm font-medium text-darksalmon">
        {{ invalid }}
      </span>
    </div>
    <div class='flex relative'>
      <input
          :id="formname"
          class="block w-full border-0 p-0 text-grey placeholder-gray-500 focus:ring-0 sm:text-sm bg-transparent"
          :placeholder="placeholder"

          :value="disabled ? '' : modelValue"

          :type="innerType"
          :name="formname"
          :readonly="readonly"
          :disabled="disabled"
          tabindex="0"
          :min="min"
          :max="max"
          :step="step"
          :minlength="minlength"
          :maxlength="maxlength"
          :autocomplete='autocomplete'

          @input="sendInput($event)"
          @blur="!!modelValue ? $emit('validate') : ''; $emit('blur')"
          @focus="!!modelValue ? $emit('validate') : ''; $emit('focus')"

          :class="{'cursor-not-allowed bg-gray-100': disabled, '': small === false}"
      >
      <template v-if="buttonName">
        <div class='-mr-4'>
          <Button @click="$emit('action')" :small="small" variant="gray-button">
            {{ buttonName }}
          </Button>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import Button from './Button.vue'

export default {
  name: "FormsInput",
  components: {
    Button
  },
  emits: ['update:modelValue', 'buffered', 'validate', 'action', 'focus', 'blur'],
  props: {
    modelValue: {
      required: true
    },
    formname: {
      default: "",
      type: String,
      required: true
    },
    label: {
      default: "Input text",
      type: String
    },
    invalid: {
      default: '',
      type: String
    },
    type: {
      default: "text",
      type: String
    },
    small: {
      default: false,
      type: Boolean
    },
    buttonName: {
      default: "",
      type: String
    },
    min: {
      default: undefined,
      type: Number
    },
    max: {
      default: undefined,
      type: Number
    },
    step: {
      default: undefined,
      type: Number
    },
    minlength: {
      default: undefined,
      type: Number
    },
    maxlength: {
      default: undefined,
      type: Number
    },
    placeholder: {
      default: undefined,
      type: String
    },
    required: {
      default: false,
      type: Boolean
    },
    readonly: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    nolabel: {
      default: false,
      type: Boolean
    },
    autocomplete: {
      default: null,
      type: String
    },
    firstInFieldset: {
      default: false,
      type: Boolean
    },
    lastInFieldset: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      buffer: null,
      innerType: null
    }
  },
  methods: {
    sendInput(e) {
      console.log("Emitting")
      if (this.type === 'number') {
        this.$emit('update:modelValue', parseFloat(e.target.value))
      } else {
        this.$emit('update:modelValue', e.target.value)
      }
      let that = this // This is crucial to keep buffer running !!
      clearTimeout(that.buffer)
      that.buffer = setTimeout(() => {
        that.$emit('buffered', e.target.value, e)
      }, 400)
    }
  }
}
</script>
