import './tailwind.css'
import axios from 'axios'
import App from './App.vue'
import { createApp } from 'vue'
import io from 'socket.io-client'
import VueClickAway from 'vue3-click-away'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes.js'
import { createPinia } from 'pinia'


const app = createApp(App)

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withCredentials = true
axios.defaults.baseURL = import.meta.env.VITE_STRAPI_URL

const router = createRouter({
  history: createWebHistory(),
  routes
})
app.use(router)

app.use(VueClickAway)

app.use(VueReCaptcha, { siteKey: '6LeT2SogAAAAAF-CHqyS4gk_SGOzuXSbhxPBfX6N' })

const url = import.meta.env.VITE_WEBSOCKET_URL
// console.log(url);
const socket = io(url, {
  path: '/uploader/',
  autoConnect: false,
  transports: ['websocket'],
  withCredentials: true
})
socket.on('connect', () => {
  console.log('[socket] connected', socket.id)
})
socket.on('disconnect', () => {
  console.log('[socket] disconnected')
})
app.config.globalProperties.$socket = socket

const pinia = createPinia()
app.use(pinia)

app.mount('#app')