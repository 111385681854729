const types = [
  {
    type: 'identification',
    title: 'Průkaz totožnosti',
    detail: 'Občanský průkaz nebo cestovní pas',

    level_limit: 100,
    journey_limit: null,
    journey: 'lvl100_verifyID',
    special: 'identityForm',

    client_upload: true,
    template: false,
  },
  {  
    type: 'income_proof',
    title: 'Výpis příjmů za poslední dva měsíce',
    detail: 'Výpis (příjmů) z bankovního účtu',

    level_limit: 100,
    journey_limit: null,
    journey: 'lvl200_uploadIncome',
    special: null,

    client_upload: true,
    template: false,
  },
  {
    type: 'savings',
    title: 'Doložení vlastních prostředků',
    detail: 'Startovací náklady s Ownestem jsou 3 % z ceny vybrané nemovitosti. Doložte, že tyto prostředky vlastníte. Může se jednat o výpis z bankovního účtu nebo z jiných typů spoření či investic.',

    level_limit: 200,
    journey_limit: null,
    journey: 'lvl200_verifySavings',
    special: null,

    client_upload: true,
    template: false,
  },
  {
    type: 'liability',
    title: 'Závazky',
    detail: 'Máte finanční závazky? Prosíme, nahrajte zde dokumenty, které potvrzují jejich výši. Typicky se jedná o úvěrové smlouvy nebo leasing na auto.',

    level_limit: 200,
    journey_limit: null,
    journey: 'lvl200_uploadLiabilities',
    special: 'overThousand',

    client_upload: true,
    template: false,
  },
  {
    type: 'income_proof_ownest',
    title: 'Potvrzení o výši příjmu',
    detail: 'Potvrzení o výši příjmu | Čestné prohlášení o výši příjmů',

    level_limit: 200,
    journey_limit: null,
    journey: 'lvl100_verifyIncomeOwnest',
    special: null,

    client_upload: true,
    template: true,
    files: [
      {
        name: 'Potvrzení o výši příjmů - zaměstnanc',
        url: 'potvrzeni_o_vysi_prijmu_zamestnanec.pdf'
      },
      {
        name: 'Potvrzení o výši příjmů - OSVC',
        url: 'potvrzeni_o_vysi_prijmu_osvc.pdf'
      }
    ]
  },
  {
    type: 'partner_income_proof_ownest',
    title: 'Potvrzení o výši příjmu partnera/ky',
    detail: 'Potvrzení o výši příjmu | Čestné prohlášení o výši příjmů',

    level_limit: 200,
    journey_limit: null,
    journey: 'lvl100_verifyIncomeOwnest',
    special: 'hasPartner',

    client_upload: true,
    template: true,
  },

  // mortgage
  {
    type: 'czech_id',
    title: 'Průkaz totožnosti',
    client_upload: true,
  },{
    type: 'passport',
    title: 'Cestovní pas',
    client_upload: true,
  },{
    type: 'proof_of_stay',
    title: 'Potvrzení o pobytu',
    client_upload: true,
  }
]


const getUploadTypes = types.filter(i => i.client_upload === true)

const getTemplateTypes = types.filter(i => i.template === true)

const getModalTypes = types.filter(i => i.client_upload === false && i.template === true)


const findByType = (type) => {
  return types.find(i => i.type === type)
}


export default {
  types,

  getUploadTypes,
  getTemplateTypes,
  getModalTypes,
  
  findByType
}
