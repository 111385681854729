import {format} from "money-formatter"

const timePad = (value) => value > 9 ? value : '0' + value

export const date = (date) => {
    if (!date)
        return null
    let d = new Date(date)
    return `${d.getDate()}. ${d.getMonth() + 1}. ${d.getFullYear()}`
}

export const datetime = (date) => {
    if (!date)
        return null
    let d = new Date(date)
    return `${d.getDate()}. ${d.getMonth() + 1}. ${d.getFullYear()} ${timePad(d.getHours())}:${timePad(d.getMinutes())}`
}

export const kilobytes = (value, decimal = 0) => {
    return format('kB', Math.floor((value ?? 0) / 1024), decimal, false).replaceAll(',', ' ')
}

export const getStorageUrl = () => {
    return import.meta.env.VITE_STORAGE_URL;
}
