<template>
  <div class="flex flex-col w-full rounded-2xl">
    <div class="rounded-2xl reader-wrapper">
      <div
        id="reader"
        class="mx-auto text-center bg-dark rounded-2xl"
      ></div>

      <TitleBlock
        v-if="loading || !init"
        title="Načítáme připojení ke kameře"
        loading
      />     

      <div v-if="deviceId === null && !loading && init">

        <TitleBlock
          v-if="!notAvailable"
          title="Vyberte si jednu z kamer na vašem zařízení."
          img="/graphics/undraw_choice_re_2hkp.svg"
          imgAlt="Vyberte si kameru z nabídky."
        />

        <TitleBlock
          v-else
          title="Nepodařilo se nám připojit k vaší kameře."
          subtitle="Zkuste obnovit tuto stránku a povolit přístup k použití zařízení."
          img="/graphics/register-error.svg"
          imgAlt="Spojení ukončeno."
        />

      </div>
    </div>
    <CamPicker
      :cameras="cameras"
      :deviceId="deviceId"
      :back_cameras="back_cameras"
      :front_cameras="front_cameras"
      :generic_cameras="generic_cameras"
      @change="changeCamera"
    />
  </div>
</template>

<script>
import { 
  Html5Qrcode, 
  Html5QrcodeScannerState, 
  Html5QrcodeSupportedFormats
} from 'html5-qrcode'
import CamPicker from '@/components/CamPicker.vue'
import TitleBlock from '@/components/TitleBlock.vue'

export default {
  components: {
    CamPicker,
    TitleBlock
  },
  emits: ['scanned'],
  props: {
    preferCamerasWithLabel: {
        type: Array,
        default: ['back', 'usb', 'zadni', 'zadní', 'enviroment'],
    },
    constraints: {
        type: Object,
        default: { video: { width: {ideal: 2560}, height: {ideal: 1440} }, facingMode: 'environment' }
    },
    config: {
      type: Object,
      default: {
        fps: 15,
        qrbox: 150,
        formatsToSupport: [
          Html5QrcodeSupportedFormats.QR_CODE,
          Html5QrcodeSupportedFormats.AZTEC,
          Html5QrcodeSupportedFormats.CODABAR,
          Html5QrcodeSupportedFormats.CODE_39,
          Html5QrcodeSupportedFormats.CODE_93,
          Html5QrcodeSupportedFormats.CODE_128,
          Html5QrcodeSupportedFormats.DATA_MATRIX,
          Html5QrcodeSupportedFormats.MAXICODE,
          Html5QrcodeSupportedFormats.ITF,
          Html5QrcodeSupportedFormats.EAN_13,
          Html5QrcodeSupportedFormats.EAN_8,
          Html5QrcodeSupportedFormats.PDF_417,
          Html5QrcodeSupportedFormats.RSS_14,
          Html5QrcodeSupportedFormats.RSS_EXPANDED,
          Html5QrcodeSupportedFormats.UPC_A,
          Html5QrcodeSupportedFormats.UPC_E,
          Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
        ], //todo
      }
    },
    allowDuplicityScanning: {
        type: Boolean,
        default: false
    },
    // in ms
    allowDuplicityScanningDelay: {
        type: Number,
        default: 2000
    },
  },
  data: () => {
    return {
      scanned: [],
      lastError: null,
      canSetDefaultScanColor: true,
      deviceId: null,
      cameras: [],
      html5QrCode: null,
      loading: true,
      init: false,
      notAvailable: false,
      class: 'border-scan-default',

      generic_cameras: 0,
      front_cameras: 0,
      back_cameras: 0,
    }
  },
  async mounted() {
    this.setupMedia()
    this.html5QrCode = new Html5Qrcode('reader')
    this.loading = false
    this.init = true

  },
  async beforeUnmount() {
    await this.stop()
    this.html5QrCode.clear()
  },
  methods: {
    onScanSuccess(decodedText) {
 
      if (this.canSetDefaultScanColor) 
        this.setScanClass('border-scan-scanned', true)

      if (!this.allowDuplicityScanning && this.scanned.indexOf(decodedText) === -1) {

        this.scanned.push(decodedText)
        // console.log(decodedText)
        this.$emit('scanned', decodedText)

      } else if(this.allowDuplicityScanning) {

        if (this.scanned.indexOf(decodedText) !== -1) 
          return

        if (this.allowDuplicityScanningDelay > 0) {
          this.scanned.push(decodedText)

          setTimeout(() => {
            this.scanned = this.scanned.filter(el => el !== decodedText)
          }, this.allowDuplicityScanningDelay)
        }

        this.$emit('scanned', decodedText)

      } else {

        if (this.lastError !== decodedText) {

          this.lastError = decodedText
          this.setScanClass('border-scan-error')

          setTimeout(() => {
            if (this.lastError === decodedText)
              this.lastError = null
          }, 5500)
        }
      }

      if ( ! (this.allowDuplicityScanning) && (this.lastError === null || this.lastError !== decodedText)) {
        this.lastError = decodedText
        setTimeout(() => {
          if (this.lastError === decodedText) {
            this.lastError = null
          }
        }, 3000)
      }
    },

    loadCameras() {
      try {
        navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
          for (let i = 0; i !== deviceInfos.length; ++i) {
              let deviceInfo = deviceInfos[i]
              if (deviceInfo.kind === 'videoinput' && this.cameras.find(el => el.device.deviceId === deviceInfo.deviceId) === undefined) {
                this.cameras.push({
                  device: deviceInfo,
                  label: this.labelCamera(deviceInfo)
                })
              }
          }
        }).then(() => {
          if ( ! this.innited ) {
            if (this.deviceId === null) {
              setTimeout(() => {
                this.start()
              }, 100)
            }
            this.innited = true
          }
        })
      } catch (err) {
        this.notAvailable = true
        console.log(err)
      }
    },


    async changeCamera(deviceId) {
      // console.log('async change camera', deviceId);
        if (this.deviceId !== deviceId) {
            this.deviceId = deviceId
            // return // will be recalled due to watcher
        }
        await this.stop()
        if (deviceId)
            await this.loadCamera(deviceId)
        this.loading = false
    },
    async loadCamera(deviceId) {
        // console.log('async load camera', deviceId);
        await this.html5QrCode.start(deviceId, this.config, this.onScanSuccess)
    },


    async stop(resetDeviceId = false) {
      if (this.html5QrCode.getState() === Html5QrcodeScannerState.SCANNING || this.html5QrCode.getState() === Html5QrcodeScannerState.PAUSED) {
        await this.html5QrCode.stop()
        if (resetDeviceId) 
          this.deviceId = null
      }
    },

    start() {
      if (this.deviceId) {
        this.loadCamera(this.deviceId)
      } else {
        // // check if there is any remembered device and if so, use them
        // const rememberedDevice = window.localStorage.getItem('slgr_scan_device_id')
        // if (rememberedDevice && this.cameras.find(el => el.deviceId === rememberedDevice)) {
        //     this.deviceId = rememberedDevice
        // } else if (this.cameras.length > 1) {
        //     for (const label of this.preferCamerasWithLabel) {
        //         const camera = this.cameras.find(el => el.label.toLowerCase().indexOf(label) !== -1)
        //         if (camera) {
        //             this.deviceId = camera.deviceId
        //             break
        //         }
        //     }
        // }
        // nothing found, use first if there is any
        if ( ! this.deviceId && this.cameras.length > 0) {
          this.cameras[0].device.deviceId
        }
      }
    },

    async pause() {
      if (this.html5QrCode.getState() === Html5QrcodeScannerState.SCANNING) {
        await this.html5QrCode.pause()
      }
    },

    async resume() {
      if (this.html5QrCode.getState() === Html5QrcodeScannerState.PAUSED) {
        await this.html5QrCode.resume()
      } else if (this.deviceId && this.html5QrCode.getState() === Html5QrcodeScannerState.NOT_STARTED) {
        await this.html5QrCode.start(this.deviceId, this.config, this.onScanSuccess)
      }
    },



    /** helpers */
    legacyGetUserMediaSupport() {
      return constraints => {
        let getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia

        if (!getUserMedia) {
          this.notAvailable = true
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser')
          )
        }
        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    },
    testMediaAccess() {
      navigator.mediaDevices.getUserMedia(this.buildConstraints()).then(stream => {
        let tracks = stream.getTracks()
        tracks.forEach(track => {
          track.stop()
        })
        this.loadCameras()
      }).catch(err => {
        this.notAvailable = true
        console.log(err)
      })
    },
    setupMedia() {
        if (navigator.mediaDevices === undefined)
          navigator.mediaDevices = {}
        if (navigator.mediaDevices.getUserMedia === undefined)
          navigator.mediaDevices.getUserMedia = this.legacyGetUserMediaSupport()
        this.testMediaAccess()
    },
    buildConstraints(deviceId) {
      const constraints = { video: true, audio: false }
      const c = { ...constraints, ...this.constraints }
      if (deviceId) {
        if (typeof c.video !== 'object' || c.video === null)
          c.video = {}
        c.video.deviceId = { exact: deviceId }
      }
      return c
    },

    setScanClass(className, canSetDefaultScanColor = false, duration = 1000) {
      this.canSetDefaultScanColor = canSetDefaultScanColor
      if (this.class === className) 
        return
      this.class = className
      setTimeout(() => {
        // if class name changed, then another timeout was set too
        if (this.class === className) {
          this.canSetDefaultScanColor = true
          this.class = 'border-scan-default'
        }
      }, duration)
    },

    labelCamera(cam) {
      let cout = {}  
      if (cam.label.toLowerCase().includes('front')) {
        cout.front_cam = 'Přední kamera'
        cout.front_index = this.front_cameras
        this.front_cameras++
      }
      else if (cam.label.toLowerCase().includes('back')) {
        cout.back_cam = 'Zadní kamera'
        cout.back_index = this.back_cameras
        this.back_cameras++
      }
      else {
        cout.generic_cam = 'Kamera'
        cout.generic_index = this.generic_cameras
        this.generic_cameras++
      }
      return cout
    }
  },
  // watch: {
  //   deviceId: async (newVal) => {
  //     await this.changeCamera(newVal)
  //   },
  // },
}
</script>

<style>
  .reader-wrapper video {
    width: 100% !important;
    @apply rounded-2xl;
  }
  .reader-wrapper #qr-shaded-region {
    @apply rounded-2xl;
  }
  .reader-wrapper #qr-shaded-region div {
    @apply rounded-none;
  }
</style>
