<template>
  <div v-if="false">
    <span :class="{'underline': completeFailure}" @click="completeFailure = !completeFailure">completeFailure</span> | 
    <span :class="{'underline': closedSession}" @click="closedSession = !closedSession">closedSession</span> | 
    <span :class="{'underline': success}" @click="success = !success">success</span> | 
    <span :class="{'underline': apology}" @click="apology = !apology">apology</span> | 
    <span :class="{'underline': loading}" @click="loading = !loading">loading</span>
  </div>
  <Layout>
    <transition name="fade" mode="out-in">

      <TitleBlock 
        v-if="completeFailure"
        title="Spojení&nbsp;s portálem Můj&nbsp;Ownest se&nbsp;nezdařilo."
        subtitle="Zkuste to prosím později."
        :img="global.modeBZR ? '/graphics/undraw_Error_connection_mortgage.svg' : '/graphics/undraw_Error_connection.svg'"
        imgAlt="Spojení se nezdařilo."
      >
        <a href="/camera" class="relative top-8 underline cursor-pointer">
          Načíst nový QR kód
        </a>
      </TitleBlock>

      <TitleBlock 
        v-else-if="closedSession && !success"
        title="Nahrávání zrušeno z webu."
        subtitle="Přerušeno internetové spojení."
        :img="global.modeBZR ? '/graphics/register-error_mortgage.svg' : '/graphics/register-error.svg'"
        imgAlt="Spojení ukončeno."
      >
        <a href="/camera" class="relative top-8 underline cursor-pointer">
          Načíst nový QR kód
        </a>
      </TitleBlock>

      <TitleBlock 
        v-else-if="success"
        title="Úspěšně nahráno!"
        :subtitle="apology 
            ? 'Nyní můžete aktualizovat okno ke svému prohlížeči.' 
            : mobile ? (user.role === 1 ? 'mobile-link' : 'mobile-link-mortgage') : 'Nyní se můžete vrátit ke svému prohlížeči.'"
        :img="requestId ? '/graphics/undraw_my_app_success_mortgage.svg' : '/graphics/undraw_my_app_success_grtj.svg'"
        imgAlt="Hotovo, děkujeme!"
      >
        <span></span>
      </TitleBlock>

      <div v-else-if="!loading" class="sm:px-8 px-4 pb-2">
        <form @submit.prevent="upload()" class="w-full max-w-sm mx-auto flex flex-col space-y-4">


          <!-- <div v-if="false" class="pb-8">
            <h2 class="mb-2 font-bold  text-2xl text-center text-grey">
              Nahrání souboru na váš profil Ownest
            </h2>
            <h3 class="font-light text-xl text-center text-grey">
              Usnadněte si práci s přenosem dokumentů.
            </h3>
          </div> -->

          <template v-if="['czech_id', 'passport', 'proof_of_stay'].includes(newDocument.type)">
            <div v-if="newDocument.type === 'czech_id'" class="pt-3 mx-auto w-full flex justify-center">
              <div class="bg-white shadow-inputs rounded-md px-4 py-4 text-center w-full">
                <span class="font-bold text-lg text-bzr-secondary flex mb-1 justify-center">
                  Nahrajte obě strany dokladu
                </span>
                <span class="text-bzr-dark leading-relaxed mt-2">
                  Potřebujeme ověřit údaje z&nbsp;obou stran 
                </span>
              </div>
            </div>

            <fieldset v-if="redo" class="pb-2">
              <label
                  for="new-description"
                  class="inline-block pb-1.5 text-bzr-grey"
              >
                Vaše zpráva
              </label>
              <textarea
                  v-model="newDocument.description"
                  name="new-description"
                  placeholder="Odpovězte zde či nahrajte nový soubor"
                  class="block w-full px-2 py-3 sm:text-base text-sm text-bzr-grey rounded-xl border border-bzr-lightgrey transition-all hover:border-bzr-dark"
                  style="resize: none;"
                  rows="4"
              />
            </fieldset>
            <RadioBzr
              v-if="redo || newDocument.name"
              v-model="newDocument.inputMethod"
              label="Zvolte metodu pořízení dokumentu"
              :items="[{
                label: 'Vyfotit kamerou',
                value: 'camera-shutter'
              },{
                label: 'Nahrát soubor',
                value: 'file-upload'
              }]"
              horizontal
              :required="!redo"
              class="pt-2"
            />

            <MultiFileUploadBzr
              v-if="newDocument.inputMethod === 'file-upload'"
              v-model="newDocument.files"
              formname="file"
              nolabel
              class="py-4"
            />

            <Camera 
              v-if="newDocument.inputMethod === 'camera-shutter'"
              :imagesAmount="['czech_id', 'proof_of_stay'].includes(newDocument.type) ? 2 : 1"
              @captured="scrollCapture"
              @capturedAll="captured"
            />
          </template>

          <template v-else-if="newDocument.type === 'identification'">
            <Select
              v-if="!redo"
              v-model="newDocument.name"
              first-in-fieldset
              last-in-fieldset
              label="Typ průkazu" formname="title"
              :items="[{
                label: 'Občanský průkaz',
                value: 'Občanský průkaz'
              },{
                label: 'Cestovní pas',
                value: 'Cestovní pas'
              },]"
            ></Select>

            <div v-if="newDocument.name === 'Občanský průkaz'" class="pt-3 mx-auto w-full flex justify-center">
              <div class="bg-white shadow-inputs rounded-md px-4 py-4 text-center w-full">
                <span class="font-bold text-lg text-secondary flex mb-1 justify-center">
                  <img alt="info circe sign" src="/graphics/i.svg" class="mr-3">
                  Nahrajte obě strany dokladu
                </span>
                <span class="text-a9 leading-relaxed mt-2">
                  Potřebujeme ověřit údaje z&nbsp;obou stran 
                </span>
              </div>
            </div>


            <fieldset v-if="redo" class="pb-2">
              <label
                  for="new-description"
                  class="inline-block pb-1.5 font-bold"
              >
                Vaše zpráva
              </label>
              <textarea
                  v-model="newDocument.description"
                  name="new-description"
                  placeholder="Odpovězte zde či nahrajte nový soubor"
                  class="w-full md:px-8 md:py-3 p-2 bg-white border-0 shadow-inputs focus:shadow-inputs-blue rounded-lg"
                  style="resize: none;"
                  rows="4"
              />
            </fieldset>
            <Radio
              v-if="redo || newDocument.name"
              v-model="newDocument.inputMethod"
              label="Zvolte metodu pořízení dokumentu"
              :items="[{
                label: 'Vyfotit kamerou',
                value: 'camera-shutter'
              },{
                label: 'Nahrát soubor',
                value: 'file-upload'
              }]"
              horizontal
              :required="!redo"
              class="pt-2"
            />

            <MultiFileUpload
              v-if="newDocument.inputMethod === 'file-upload'"
              v-model="newDocument.files"
              formname="file"
              nolabel
              class="py-4"
            />

            <Camera 
              v-if="newDocument.inputMethod === 'camera-shutter'"
              :imagesAmount="newDocument.name === 'Občanský průkaz' ? 2 : 1"
              @captured="scrollCapture"
              @capturedAll="captured"
            />
          </template>

          <template v-else>
            <fieldset>
              <label
                  for="title"
                  class="inline-block pb-1.5 font-bold"
              >
                Název<span class="text-darksalmon">*</span>
              </label>
              <input
                  v-model="newDocument.name"
                  type="text"
                  label="Název"
                  name="title"
                  class="w-full md:px-8 md:py-3 p-2 bg-white border-0 shadow-inputs focus:shadow-inputs-blue rounded-lg"
                  required
              />
            </fieldset>

            <fieldset class="pb-2">
              <label
                  for="new-description"
                  class="inline-block pb-1.5 font-bold"
              >
                Popisek
              </label>
              <textarea
                  v-model="newDocument.description"
                  name="description"
                  class="w-full md:px-8 md:py-3 p-2 bg-white border-0 shadow-inputs focus:shadow-inputs-blue rounded-lg"
                  style="resize: none;"
                  rows="4"
              />
            </fieldset>

            <MultiFileUpload
              v-model="newDocument.files"
              formname="file"
              nolabel
              class="py-4"
            />
          </template>

          <div v-if="!!newDocument.files"  class="flex flex-row justify-center space-x-6 pt-4">
            <template v-if="global.modeBZR">
              <ButtonBzr variant="primary-md" type="submit" class="w-full">
                Nahrát dokument
              </ButtonBzr>
            </template>
            <template v-else>
              <Button type="submit" class="w-full">
                Nahrát dokument
              </Button>
            </template>
          </div>

          <div class="w-full text-xs text-grey text-center">
            <p v-if="global.modeBZR">
              <a class="underline underline-offset-1 hover:no-underline"
                 href="https://hypoteka.ownest.cz/" target="_blank">
                hypoteka.ownest.cz
              </a>
            </p>
            <p v-else>
              <a class="underline underline-offset-1 hover:no-underline"
                 href="https://www.ownest.cz/" target="_blank">
                www.ownest.cz
              </a>
            </p>
          </div>


        </form>
      </div>

      <TitleBlock 
        v-else
        title="Zpracováváme dokumenty"
        loading
      />

    </transition>
  </Layout>
</template>

<script>
import Input from '@/components/forms/Input.vue'
import Radio from '@/components/forms/Radio.vue'
import Select from '@/components/forms/Select.vue'
import Button from '@/components/forms/Button.vue'
import Textarea from '@/components/forms/Textarea.vue'
import MultiFileUpload from '@/components/forms/MultiFileUploadNeo.vue'

import ButtonBzr from '@/components/forms-bzr/Button.vue'
import RadioBzr from '@/components/forms-bzr/Radio.vue'
import MultiFileUploadBzr from '@/components/forms-bzr/MultiFileUploadNeo.vue'

import Layout from '@/views/_Layout.vue';
import Camera from '@/components/Camera.vue';
import TitleBlock from '@/components/TitleBlock.vue';

import axios from 'axios'
import {getOne, login, fileUpload, update, create} from '@/api'
import documents from '@/helpers/documentTypes.js'
import globalStore from '@/store.js'

export default {
  name: "Uploader-app",
  components: {
    Input,
    Radio,
    Select,
    Button,
    Textarea,
    MultiFileUpload,
    ButtonBzr,
    RadioBzr,
    MultiFileUploadBzr,
    Layout,
    Camera,
    TitleBlock
  },
  data() {
    return {
      global: globalStore(),
      user: {
        id: null,
        role: null,
        token: null,
        one_time: null,
      },
      redo: false,
      newDocument: {
        id: null,
        name: 'Občanský průkaz',
        type: 'identification',
        description: null,
        inputMethod: 'camera-shutter',
        files: null
      },
      mobile: false,
      requestId: null,

      completeFailure: false,
      failedUpload: null,
      apology: false,
      closedSession: false,
      success: false,
      loading: false
    }
  },
  methods: {
    async upload() {
      this.checkForm()
      if (this.failedUpload)
        return

      this.loading = true
      this.success = false
      console.log('uploading...');
      try {
        const formData = new FormData()
        this.newDocument.files.forEach((file) => {
          if (file.raw) {
            formData.append('files', file.raw, 'uploader_'+this.newDocument.type);
          } else {
            formData.append('files', file, file.name);
          }
        })
        const res = await fileUpload(formData)
        console.log('uploaded', res, res.map(i => i.id))

        let final = null
        if (this.requestId) {
          try {
            final = await create('documents', {
              files: res.map(i => i.id),
              user: this.user.id,
              title: this.newDocument.name,
              description: this.newDocument.description,
              document_request: this.requestId,
              
              type: 'identification',
              client_upload: true,
              requires_client_signature: false,
              requires_redo: false
            })
            console.log('created', final)
          } catch (e) {
            console.error('create failed', e)
            this.newDocument.files = null
            this.loading = false
            this.success = false
            this.$socket.emit('error', { id: this.user.one_time })
            return
          }
        } else if(this.redo) {
          final = await update('documents', this.newDocument.id, {
            files: res.map(i => i.id),
            redo_response: this.newDocument.description ?? 'Žádnou poznámku neuvedl',
            requires_redo: false,
            user: this.user.id
          }).catch(e => {
            console.error('update failed', e)
            this.newDocument.files = null
            this.loading = false
            this.success = false
            this.$socket.emit('error', { id: this.user.one_time })
            return
          })
          // console.log('updated', final)
        } else {
          try {
            final = await create('documents', {
              files: res.map(i => i.id),
              user: this.user.id,
              title: this.newDocument.name,
              description: this.newDocument.description,
              type: this.newDocument.type,
              client_upload: true,
              requires_client_signature: false,
              requires_redo: false
            })
            console.log('created', final)
          } catch (e) {
            console.error('create failed', e)
            this.newDocument.files = null
            this.loading = false
            this.success = false
            this.$socket.emit('error', { id: this.user.one_time })
            return
          }
        }
        
        this.failedUpload = null
        this.$socket.emit('success', { 
          id: this.user.one_time,
          newDocument: final.data.id
        })

        this.newDocument.files = null
        setTimeout(() => {
          this.loading = false
          this.success = true
        }, 300)

      } catch (e) {
        console.error('UPLOAD FAILED', e)
        this.newDocument.files = null

        this.$socket.emit('error', { id: this.user.one_time })

        setTimeout(() => {
          this.loading = false
          this.success = false
        }, 200)
        return
      }
    },
    checkForm() {
      if (this.redo && (!this.newDocument.description && !this.newDocument.files)) {
        console.log("Form not complete");
        return
      }
      else if (!this.newDocument.name || !this.newDocument.files) {
        console.log("Form not complete");
        return
      }

      const LIMIT = 64 * 1024 * 1024 // 512MB file limit
      this.newDocument.files.forEach(i => {
        if (i.size >= LIMIT) {
          this.failedUpload = i
          console.error('FILE SIZE TOO BIG TO UPLOAD', i);
        }
      })
    },
    scrollCapture(blobImages) {
      if (this.newDocument.type === 'proof_of_stay')
        this.newDocument.files = blobImages
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          left: 0,
          behavior: 'smooth'
        })
      }, 20)
    },
    async captured(blobImages) {
      console.log(blobImages);
      this.newDocument.files = blobImages
    },

    async connectUsingToken(params) {
      const email = decodeURIComponent(params.e)
      const response = await login(email, params.t)
      // console.log(response);

      if (response.jwt)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.jwt

      this.user = {
        id: response.user.id,
        role: response.user.role,
        token: response.jwt,
        one_time: params.t,
      }

      if (this.redo) {
        this.newDocument.id = params.r
        // throw HTTP Error 403 if ID is invalid
        const res = await getOne('documents', this.newDocument.id)
        console.log('res', res);
        this.newDocument.name = res.data.attributes.title
      }

      this.newDocument.type = params.d
    },
    socketConnetion() {
      this.$socket.connect()
      this.$socket.on('connect_error', () => {
        // console.log('[socket] connection error rulez');
        this.apology = true
        this.$socket.disconnect();
      });

      // join same socket.io broadcast session as the customer portal modal
      this.$socket.emit('session', { id: this.user.one_time })

      this.$socket.on('closed', () => {
        this.closedSession = true
      })
    },
    checkUrls() {
      const search = this.$route.query
      if (
        !search.e || 
        !search.d || 
        !search.t || 
        !this.matched(search.d)) {

        console.log('Some parameters are invalid or missing!');
        this.$router.push({name: 'Camera'})
      }
      if (search.r) {
        console.log('Document redo mode')
        this.redo = true
      }
      this.mobile = search?.m === '1' 
      this.requestId = search?.req

      this.global.consumeQuery(this.$route.query)
      return search
    },
    matched(docType) {
      let x = documents.getUploadTypes
      // console.log('doc validating', x, docType);
      return x.some(i => {
        if (i.type === docType) {
          this.docName = i.title
          return true
        }
        return false
      });
    }
  },
  async mounted() {
    try {
      let params = this.checkUrls()
      await this.connectUsingToken(params)
      this.socketConnetion()
    } catch (e) {
      console.log('Not validated, stop execution:', e?.message);
      this.completeFailure = true
      return
    }
  }
}
</script>
