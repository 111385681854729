<template>
  <router-link
      v-if="!!to"
      ref="root"
      :to="to"
      :disabled="disabled"
      tabindex="0"
      :class="[
        small ? variant + '-sm' : variant,
        shine ? 'shiny-button' : '',
        selected ? variant + '-selected' : '']"
      @mousemove="mouseMove"
  >
    <slot>Button</slot>
  </router-link>
  <a
      v-else-if="!!href"
      ref="root"
      :href="href"
      target="_blank"
      :disabled="disabled"
      tabindex="0"
      class="block"
      :class="[
        small ? variant + '-sm' : variant,
        shine ? 'shiny-button' : '',
        selected ? variant + '-selected' : '']"
      @mousemove="mouseMove"
  >
    <slot>Button</slot>
  </a>
  <button
      v-else
      ref="root"
      :type="type"
      :disabled="disabled"
      tabindex="0"
      :class="[
        small ? variant + '-sm' : variant,
        shine ? 'shiny-button' : '',
        selected ? variant + '-selected' : '']"
      @mousemove="mouseMove"
  >
    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  name: "FormsButton",
  props: {
    type: {
      default: 'button',
      type: String
    },
    variant: {
      default: 'primary',
      type: String
    },
    to: {
      default: null,
      type: Object
    },
    href: {
      default: null,
      type: String
    },
    small: {
      default: false,
      type: Boolean
    },
    shine: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },

    // CompleteCalc component
    selected: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      buffer: null,
    }
  },
  methods: {
    mouseMove(e) {
      let el = this.$refs.root
      if (el === null) {
        return;
      }
      if (typeof el.$el !== 'undefined') {
        el = el.$el;
      }
      if(!el || !this.shine)
        return

      const { x, y } = el.getBoundingClientRect();
      e.target.style.setProperty("--x", e.clientX - x);
      e.target.style.setProperty("--y", e.clientY - y);
    }
  }
}
</script>

<style scoped>
.primary.shiny-button {
  @apply hover:bg-primary hover:text-dark
}

.primary, .secondary, .info, .danger, .primary-md, .secondary-md, .info-md, .danger-md, .danger-sm, .primary-sm, .secondary-sm, .info-sm, .primary-xs, .secondary-xs, .info-xs, .danger-xs {
  @apply whitespace-nowrap inline-flex items-center justify-center bg-origin-border select-none transition-all shadow-button hover:shadow-inset-button-hover
}

.primary, .secondary, .info, .danger {
  @apply h-11 xl:px-12 px-8 py-2 border-0 rounded-md hover:shadow-inset-button-hover font-bold;
}

.primary-md, .secondary-md, .info-md, .danger-md {
  @apply h-9 xl:px-8 px-4 py-2 border-0 rounded-md text-sm font-bold;
}

.danger-sm, .primary-sm, .secondary-sm, .info-sm {
  @apply h-7 text-sm xl:px-8 px-6 py-2 border-0 rounded-md  font-bold;
}

.primary-xs, .secondary-xs, .info-xs, .danger-xs {
  @apply h-6
  text-[0.8rem] xl:px-4 px-3 py-2 border-0 rounded-md font-bold;
}

.primary, .primary-md, .primary-sm, .primary-xs {
  @apply bg-primary text-dark hover:text-primary hover:bg-dark;
}
.secondary, .secondary-md, .secondary-sm, .secondary-xs {
  @apply bg-secondary/40 text-dark hover:text-white hover:bg-dark;
}
.info, .info-md, .info-sm, .info-xs {
  @apply bg-white text-dark hover:text-white hover:bg-dark;
}
.danger, .danger-md, .danger-sm, .danger-xs {
  @apply bg-ownest-danger text-white hover:bg-ownest-dark hover:text-ownest-danger;
}

.shiny-button {
  /* add this property to our button, */
  /* so we can position our shiny gradient *relative* to the button itself */
  position: relative;
  /* then, make sure our shiny effect */
  /* doesn't "overflow" outside of our button */
  transform-style: preserve-3d;
  overflow: hidden;
  padding: 10px 28px 10px 28px;
}
.shiny-button:hover::after {
  opacity: 0.3;
}
.shiny-button:active::after {
  opacity: 0;
}
.shiny-button::after {
  /* all pseudo-elements need "content" to work. We'll make it empty here */
  content: '';
  opacity: 0;
  padding:0;
  top: calc(var(--y, 0) * 1px - 100px);
  left: calc(var(--x, 0) * 1px - 100px);
  position: absolute;
  width: 200px;
  height: 200px;
  /* make sure the gradient isn't too bright */
  /* add a circular gradient that fades out on the edges */
  background: radial-gradient(#ffffff, #C8F60700 80%);
  transition: opacity 0.6s;
  transform: translateZ(-1px);
}

.shiny-button:focus-visible {
  @apply outline-dark outline-2
}
.shiny-button:active {
  @apply outline-none
}
</style>
