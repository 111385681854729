<template>
  <Layout>
    <TitleBlock
      v-if="alert"
      title="Tento kód nemá platný formát, zkuste to prosím znovu."
    />

    <Scanner 
      class="w-full px-4" 
      @scanned="handler"
      allowDuplicityScanning
    />
  </Layout>
</template>

<script>
import Layout from '@/views/_Layout.vue'
import Scanner from '@/components/Scanner.vue'
import TitleBlock from '@/components/TitleBlock.vue'

export default {
  components: {
    Layout,
    Scanner,
    TitleBlock
  },
  data() {
    return {
      alert: false
    }
  },
  methods: {
    getUrlParam(href, name) {
      if (typeof name !== 'string') return "";
      href = href || window.location.href;
      name = name.replace(/\[/, "\\[").replace(/\]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(href);
      return results ? decodeURIComponent(results[1]) : "";
    },

    async handler(scanned) {
      try {
        const param_e = this.getUrlParam(scanned, 'e')
        const param_d = this.getUrlParam(scanned, 'd')
        const param_t = this.getUrlParam(scanned, 't')
        const param_r = this.getUrlParam(scanned, 'r')
        const param_m = this.getUrlParam(scanned, 'm')
        const param_req = this.getUrlParam(scanned, 'req')

        if (param_e && param_d && param_t) {
        
          this.$router.push({
            name: 'Index',
            query: {
              e: param_e,
              d: param_d,
              t: param_t,
              r: param_r,
              m: param_m,
              req: param_req
            }
          })

        } else
          this.alert = true
      } catch (e) {
        this.alert = true
      }
    }
  }
}
</script>
