<template>
  <div class="sm:px-8 px-4 py-6 sm:mt-8 mt-4">
    <div class="text-center pb-8">
      <div class="w-full">
        <div class="px-2">
          <h3 
            class="mt-4 font-bold text-2xl text-dark" 
            v-html="title"
          ></h3>
          <a 
            v-if="subtitle === 'mobile-link'" 
            class="inline-block mt-4 text-grey underline hover:no-underline cursor-pointer"
            href="https://my.ownest.cz/documents"
          >Pokračovat do portálu</a>
          <a 
            v-if="subtitle === 'mobile-link-mortgage'" 
            class="inline-block mt-4 text-grey underline hover:no-underline cursor-pointer"
            href="https://hypoteka.ownest.cz"
          >Pokračovat do portálu</a>
          <p 
            v-else-if="subtitle" 
            class="mt-4 text-grey" 
            v-html="subtitle"
          ></p>
        </div>

        <img 
          v-if="img" 
          :src="img" :alt="imgAlt" 
          class="w-56 mx-auto mt-4"
        >
        <div v-else-if="loading" class="x-min mx-auto pt-6">
          <div class="animate-spin inline-block w-8 h-8 border-4 border-secondary border-t-white rounded-full" role="status">
            <span class="hidden">Loading...</span>
          </div>
        </div>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    },
    imgAlt: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>