<template>
  <div>
    <label
        :for="formname + '-upload'"
        @dragover="e => e.preventDefault()"
        @dragleave="e => e.preventDefault()"
        @drop="handleDrop($event)"
    >
      <span v-if="!nolabel" class="block mb-1 text-sm font-medium text-grey">
        {{ label }}<span v-if="required" class="text-darksalmon">*</span>
      </span>
      <div
          v-if="newFiles.length === 0"
          ref="uploader"
          @keydown.prevent.enter="$refs.uploader.click()"
          tabindex="0"
          class="px-4 py-6 text-grey bg-white cursor-pointer text-center shadow-inputs hover:shadow-inputs-blue outline-2 outline-dashed outline-secondary !outline-offset-0 focus-visible:!outline-2 focus-visible:!outline-dashed focus-visible:!outline-secondary rounded-lg group"
      >
        <DocumentPlusIcon class="w-8 h-8 mx-auto mb-3"/>
        <p>
          Přetáhněte soubor/y sem<br/>nebo
          <span class="text-secondary font-bold group-focus:underline group-hover:underline">klikněte pro vybrání</span>
        </p>
      </div>
    </label>

    <input
        :id="formname + '-upload'"
        :name="formname + '-upload'"
        type="file"
        accept="application/pdf,image/png,image/jpg,image/jpeg,image/jfif,image/webp,image/heic,image/heif"
        multiple
        :value="value"
        @click="value = null"
        @change="handleFile($event)"
        class="hidden"
    />

    <div v-for="(file, index) in newFiles" :key="'asdasd/' + index"
         :class="file.error ? 'outline-darksalmon shadow-inputs-red' : 'outline-grey shadow-inputs hover:shadow-inputs-blue'"
         class="relative flex justify-between w-full p-2 my-3 outline-2 outline-dashed transition-all duration-100 ease-linear rounded-md">
      <div class="w-min px-2 flex flex-col items-end justify-center">
        <span v-if="file.error === 'loadingHeic'">
          <div class="w-full h-full bg-white/75 rounded flex justify-center items-center">
            <div class="animate-spin inline-block w-6 h-6 border-4 border-secondary border-t-transparent rounded-full" role="status">
              <span class="hidden">Loading...</span>
            </div>
          </div>
        </span>
        <ExclamationIcon v-else-if="file.error" class="w-6 h-6 text-darksalmon"/>
        <PhotoIcon v-else-if="file.type.includes('image')" class="w-6 h-6"/>
        <DocumentIcon v-else class="w-6 h-6"/>
      </div>
      <div :class="file.error ? 'w-11/12' : 'w-4/5'">
        <p class="text-base font-medium text-grey truncate">
          {{ file.name }}
        </p>
        <p class="text-sm text-grey truncate">
          <span v-if="file.error === 'loadingHeic'">
            Konvertuji na formát JPG
          </span>
          <span v-else-if="file.error === 'format'">
            Nepodporovaný formát
          </span>
          <span v-else-if="file.error === 'size'">
            Přesažen limit 64MB na soubor
          </span>
          <span v-else>
            {{ kilobytes(file.size) }}
          </span>
        </p>
      </div>
      <div class="w-min pr-2 flex flex-col items-end justify-center">
        <TrashConfirm 
            :non-interactive="!!file.error"
            confirm-text="Odebrat soubor?"
            @delete="removeFile(index)" 
        />
      </div>
    </div>

    <label
        :for="formname + '-upload'"
        @dragover="e => e.preventDefault()"
        @dragleave="e => e.preventDefault()"
        @drop="handleDrop($event)"
        v-if="newFiles.length !== 0"
    >
      <div
          ref="uploader"
          @keydown.prevent.enter="$refs.uploader.click()"
          tabindex="0"
          class="mx-4 mt-3 p-2 text-gray-600 hover:text-gray-700 flex flex-row justify-center items-center space-x-3 hover:underline cursor-pointer outline-2 outline-dashed outline-gray-400 shadow-inputs hover:shadow-inputs-blue rounded-lg transition-colors ease-in-out"
      >
        <DocumentPlusIcon class="w-6 h-6"/>
        <span>
          Přidejte soubory
        </span>
      </div>
    </label>

    <span v-if="invalid" class="block mt-1 text-sm font-medium text-darksalmon">
      {{ invalid }}
    </span>
  </div>
</template>

<script>
import heic2any from '@tuily/heic2any'
import {kilobytes} from '@/helpers/index'
import {filePath} from '@/api/index'
import TrashConfirm from '@/components/TrashConfirm.vue'
import {ExclamationCircleIcon, PhotoIcon, DocumentIcon, DocumentPlusIcon} from '@heroicons/vue/24/solid'

export default {
  name: "FormsFileUpload",
  setup() {
    return {
      filePath
    }
  },
  components: {
    TrashConfirm,
    DocumentIcon,
    PhotoIcon,
    DocumentPlusIcon,
    ExclamationCircleIcon
  },
  props: {
    modelValue: {
      required: true
    },
    label: {
      default: "Input text",
      type: String
    },
    invalid: {
      default: '',
      type: String
    },
    formname: {
      default: "",
      type: String,
      required: true
    },
    nolabel: {
      default: false,
      type: Boolean
    },
    required: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
      value: null,
      newFiles: [],
      sizeLimit: 64 * 1024 * 1024 // 64MB
    }
  },
  methods: {
    kilobytes,
    handleFile(event) {
      if(this.newFiles.length > 0) {
        this.newFiles.push(...Array.from(event.target.files))
      } else {
        this.newFiles = Array.from(event.target.files) // FileList is read-only, create generic Array
      }
      // console.log('files', this.newFiles);
      this.prepareFiles()
    },
    handleDrop(event) {
      event.preventDefault()
      if(this.newFiles.length > 0) {
        this.newFiles.push(...Array.from(event.dataTransfer.files))
      } else {
        this.newFiles = Array.from(event.dataTransfer.files)
      }
      this.prepareFiles()
    },

    async prepareFiles() {
      this.$emit('update:modelValue', [])

      let formData = new FormData()
      const allowed_extensions = [
        "pdf", "png", "jpg", "jpeg", "jfif", "webp", "heic", "heif",
        "PDF", "PNG", "JPG", "JPEG", "JFIF", "WEBP", "HEIC", "HEIF"
      ];

      for(let i = 0; i < this.newFiles.length; i++) {
        let extension = this.newFiles[i].name.split('.').pop();
        // console.log(extension, allowed_extensions);

        // HEIC files are not supported by browsers yet, convert to JPG please
        if (["heic", "heif", "HEIC", "HEIF"].includes(extension))
          this.newFiles[i].error = 'loadingHeic'

        if (this.newFiles[i].size >= this.sizeLimit)
          this.newFiles[i].error = 'size'

        if (!allowed_extensions.includes(extension))
          this.newFiles[i].error = 'format'
      }

      for(let i = 0; i < this.newFiles.length; i++) {
        const extension = this.newFiles[i].name.split('.').pop();
        // console.log(extension, allowed_extensions);

        // HEIC files are not supported by browsers yet, convert to JPG please
        if (["heic", "heif", "HEIC", "HEIF"].includes(extension)) {
          const newFilename = this.newFiles[i].name.replace(extension, 'jpg')
          const jpegBlob = await heic2any({
            blob: this.newFiles[i],
            toType: "image/jpeg",
            quality: 1
          })
          this.newFiles[i] = new File([jpegBlob], newFilename, {type: "image/jpeg"})
          this.newFiles[i].error = undefined
        }

        if (!this.newFiles[i].error)
          formData.append('files', this.newFiles[i], this.newFiles[i].name)
      }

      let isFormDataEmpty = true
      for (let p of formData) {
        isFormDataEmpty = false
        break
      }

      this.$emit('update:modelValue', isFormDataEmpty ? [] : formData)
      this.$emit('validate')
    },
    removeFile(index) {
      this.newFiles.splice(index, 1)
      this.prepareFiles()
    }
  }
}
</script>
