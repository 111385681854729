<template>
  <template v-if="global.modeBZR">
    <div
        v-if="disabled"
        class="w-min px-1.5 py-1.5 bg-[#807878] hover:bg-bzr-primary/90 rounded-full flex flex-row justify-start items-center cursor-not-allowed transition-colors ease-in-out group">
      <TrashIcon class="w-5 h-5 text-[#CCBEBC] group-hover:text-white transition-colors ease-in-out"/>
    </div>
    <div
        v-else @click="verify"
        class="w-min px-1.5 py-1.5 bg-[#807878] hover:bg-bzr-primary/90 rounded-full flex flex-row justify-start items-center cursor-pointer transition-colors ease-in-out group">
      <TrashIcon class="w-5 h-5 text-[#CCBEBC] group-hover:text-white transition-colors ease-in-out"/>
    </div>
  </template>
  <template v-else>
    <div
        v-if="disabled"
        class="w-min px-2 py-1 hover:bg-salmon rounded-full flex flex-row justify-start items-center cursor-not-allowed transition-colors ease-in-out group">
      <TrashIcon class="w-5 h-5 text-grey group-hover:text-dark"/>
    </div>
    <div
        v-else @click="verify"
        class="w-min px-2 py-1 hover:bg-salmon rounded-full flex flex-row justify-start items-center cursor-pointer transition-colors ease-in-out group">
      <TrashIcon class="w-5 h-5 text-grey group-hover:text-dark"/>
    </div>
  </template>
</template>

<script>
import {TrashIcon} from '@heroicons/vue/24/outline'
import globalStore from '@/store.js'

export default {
  name: 'TrashConfirm',
  components: {
    TrashIcon
  },
  props: {
    confirmText: {
      default: 'Skutečně odebrat?',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    nonInteractive: {
      default: false,
      type: Boolean
    }
  },
  emits: ['delete'],
  data() {
    return {
      global: globalStore()
    }
  },
  methods: {
    verify() {
      if (this.nonInteractive) {
        this.$emit('delete')
        return
      }

      let y = confirm(this.confirmText)
      if (!y)
        return
      this.$emit('delete')
    }
  }
}
</script>
