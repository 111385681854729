<template>
  <router-link
      v-if="!!to"
      ref="root"
      :to="to"
      :disabled="disabled"
      tabindex="0"
      class="uppercase whitespace-nowrap tracking-wider inline-flex items-center justify-center bg-origin-border select-none transition-all h-9 xl:px-8 px-4 py-2 rounded-md text-sm font-bold; border-0 bg-bzr-primary text-white hover:bg-bzr-primaryhover"
  >
    <slot>Button</slot>
  </router-link>
  <a
      v-else-if="!!href"
      ref="root"
      :href="href"
      :target="hrefSelf ? '_self' : '_blank'"
      :disabled="disabled"
      tabindex="0"
      class="block uppercase whitespace-nowrap tracking-wider items-center justify-center bg-origin-border select-none transition-all h-9 xl:px-8 px-4 py-2 rounded-md text-sm font-bold; border-0 bg-bzr-primary text-white hover:bg-bzr-primaryhover"
  >
    <slot>Button</slot>
  </a>
  <button
      v-else
      ref="root"
      :type="type"
      :disabled="disabled"
      tabindex="0"
      class="relative uppercase whitespace-nowrap tracking-wider inline-flex items-center justify-center bg-origin-border select-none transition-all h-9 xl:px-8 px-4 py-2 rounded-md text-sm font-bold; border-0 bg-bzr-primary text-white hover:bg-bzr-primaryhover"
  >
    <slot>Button</slot>
  </button>
</template>

<script>
export default {
  name: "FormsButtonBzr",
  props: {
    type: {
      default: 'button',
      type: String
    },
    to: {
      default: null,
      type: Object
    },
    href: {
      default: null,
      type: String
    },
    hrefSelf: {
      default: false,
      type: Boolean
    },
    shine: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  emits: ['clicked'],
  data() {
    return {
      buffer: null,
    }
  },
  methods: {
    mouseMove(e) {
      let el = this.$refs.root
      if (el === null) {
        return;
      }
      if (typeof el.$el !== 'undefined') {
        el = el.$el;
      }
      if(!el || !this.shine)
        return

      const { x, y } = el.getBoundingClientRect();
      e.target.style.setProperty("--x", e.clientX - x);
      e.target.style.setProperty("--y", e.clientY - y);
    },
    clicked(e) {
      this.$emit('clicked', e)
    }
  }
}
</script>
