<template>
  <div v-click-away="away"
       class="relative bg-white border border-gray-300 px-6 py-2 focus-within:z-10"
       :class="{
         'rounded-xl rounded-b-none border border-gray-300 border-b-0': firstInFieldset,
         'border border-gray-300 border-b-0': !firstInFieldset,
         'rounded-xl rounded-t-none': lastInFieldset,
         'bg-lightsalmon': invalid,
         'border-b': invalid && lastInFieldset,
         'bg-gray-100': disabled,
         '!border !rounded-xl': firstInFieldset && lastInFieldset
       }">
    <label :for="formname" class="text-sm font-bold text-grey flex"
           :class="{'!text-gray-400': disabled, 'text-palesalmon': invalid}">
      {{label}}<span v-if="required && !disabled" class="text-palesalmon">*</span>
      <div v-if="badge" class="bg-palepurple text-purple rounded-full text-xs font-bold h-4 mt-1 mx-2 px-1.5"> {{badge}} </div>
      <span v-if="!nolabel && invalid" class="ml-1 text-sm font-normal text-palesalmon">
        {{ invalid }}
      </span>
    </label>
    <div class="relative">

    <!-- display selected value -->
    <input
      @click="toggleList"

      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter="onEnter"
      @keydown.tab="onTab"

      type="text"
      :id="formname"
      :name="formname"
      placeholder="Search"
      :disabled="disabled"
      tabindex="0"
      readonly="readonly"

      :value="itemSelected"

      class="block w-full border-0 p-0 text-grey placeholder-gray-500 focus:ring-0 sm:text-sm bg-transparent cursor-default h-7"
    />
    <ChevronUpDownIcon
      class="absolute z-0 top-[1px] right-0 !w-5 !h-5 text-grey"
      :class='{"z-10":expand}'
      @click="toggleList" />

    <!-- items dropdown -->
    <ul
      v-if="expand"
      class="absolute z-50 mt-1 w-full max-h-64 bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-dark ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      tabindex="-1"
      role="listbox"
      aria-labelledby="listbox-label"
    >
      <li
        v-for="(item, index) in items"
        :key="'list-' + formname + '-' + index"
        @click="updateSelected(item)"
        @mouseenter="arrowCounter = index"
        :id="'listbox-option-'+item.id"
        role="option"
        :class="index === arrowCounter ? 'bg-gray-200' : 'bg-white'"
        class="text-dark cursor-pointer select-none relative py-2 pl-3 pr-9 group"
      >
        <span v-if="item.desc">
          <span :class="item.value === modelValue ? 'font-bold' : 'font-normal'" class="block truncate">
            {{ item.label }}
          </span>
          <span :class="item.value === modelValue ? 'font-normal' : 'font-light'" class="block truncate">
            {{ item.desc }}
          </span>
        </span>
        <span v-else :class="item.value === modelValue ? 'font-bold' : 'font-normal'" class="block truncate">
          {{ item.label }}
        </span>
        <span
          :class="{'hidden': item.value !== modelValue}"
          class="absolute inset-y-0 right-0 flex items-center pr-4"
        >
          <CheckIcon class="!w-5 !h-5" />
        </span>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/outline'

export default {
name: "FormsSelect",
components: {
  CheckIcon,
  ChevronUpDownIcon
},
props: {
  modelValue: {
    default: null,
    required: true
  },
  items: {
    default: new Array(),
    type: Array,
    required: true
  },
  formname: {
    default: "",
    type: String,
    required: true
  },
  label: {
    default: "Select value",
    type: String
  },
  nolabel: {
    default: false,
    type: Boolean
  },
  required: {
    default: false,
    type: Boolean
  },
  disabled: {
    default: false,
    type: Boolean
  },
  invalid: {
    default: '',
    type: String
  },
  firstInFieldset: {
    default: false,
    type: Boolean
  },
  lastInFieldset: {
    default: false,
    type: Boolean
  },
  badge: {
    default: null,
    type: String
  }
},
data() {
  return {
    listItems: [],
    arrowCounter: -1,
    expand: false,
    buffer: null
  }
},
computed: {
  itemSelected() {
    return this.modelValue === null || this.items.length === 0 ? 'Vyberte z možností...' :
      this.items.find(i => i.value == this.modelValue) === undefined ? 'Vyberte z možností...' :
      this.items.find(i => i.value == this.modelValue).label
  }
},
methods: {
  updateSelected(item) {
    if(item.value === this.modelValue)
      this.expand = false
    this.$emit('update:modelValue', item.value)
    this.away()
  },

  onArrowDown(e) {
    // e.preventDefault()
    if (this.arrowCounter < this.items.length)
      this.arrowCounter += 1
  },
  onArrowUp(e) {
    // e.preventDefault()
    if (this.arrowCounter > 0)
      this.arrowCounter -= 1
  },
  onEnter(e) {
    e.preventDefault()
    this.$emit('update:modelValue', this.items[this.arrowCounter].value)
    this.away()
  },
  onTab(e) {
    // e.preventDefault()
    this.away()
    // this.$helpers.nextFocussableElement(e)
  },

  openList() {
    this.arrowCounter = 0
    this.expand = true
  },
  away() {
    this.arrowCounter = -1
    this.expand = false
  },
  toggleList() {
    if (this.expand) {
      this.away()
    } else {
      this.openList()
    }
  }
}
}
</script>
