import Index from './views/Index.vue'
import QrCamera from './views/QrReader.vue'
import NotFound from './views/NotFound.vue'


export const routes = [
  {
    name: 'Index',
    path: '/',
    component: Index
  },
  {
    name: 'Camera',
    path: '/camera',
    component: QrCamera
  },
  {
    name: 'CatchAll',
    path: '/:path(.*)',
    component: NotFound
  }
]
