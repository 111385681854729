<template>
  <div>
    <label v-if="!nolabel" :for="formname" class="block text-sm font-serif font-medium text-grey">
      {{ label }}<span v-if="required" class="text-darksalmon">*</span>
    </label>

    <div class="mt-1">
      <textarea
          :value="modelValue"
          @input="sendInput"

          :rows="rows"
          :id="formname"
          :name="formname"
          :placeholder="placeholder"
          :required="required"
          tabindex="0"
          style="resize: none"

          @blur="!!modelValue ? $emit('validate') : ''"
          @focus="!!modelValue ? $emit('validate') : ''"

          :class="{'border-darksalmon': invalid}"
          class="block w-full px-4 py-3 sm:text-base text-sm text-grey rounded-xl shadow-inputs focus:shadow-inputs-blue"
      />
    </div>

    <span v-if="!nolabel && invalid" class="block mt-1 text-sm font-medium text-darksalmon">
      {{ invalid }}
    </span>
  </div>
</template>

<script>
export default {
  name: "AdminTextarea",
  props: {
    rows: {
      default: 4,
      type: Number
    },
    modelValue: {
      required: true
    },
    formname: {
      default: "",
      type: String,
      required: true
    },
    label: {
      default: "Textarea",
      type: String
    },
    invalid: {
      default: '',
      type: String
    },
    placeholder: {
      default: undefined,
      type: String
    },
    required: {
      default: false,
      type: Boolean
    },
    nolabel: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      buffer: null
    }
  },
  methods: {
    sendInput(e) {
      this.$emit('update:modelValue', e.target.value)
      let that = this // This is crucial to keep buffer running !!
      clearTimeout(that.buffer)
        that.buffer = setTimeout(() => {
        that.$emit('buffered', e.target.value, e)
      }, 400)
    }
  }
}
</script>
