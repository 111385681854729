<template>
  <div :class="global.modeBZR ? 'font-bzr' : 'font-ownest'" class="w-full sm:px-6 sm:py-16 px-0 py-0 flex flex-col items-center justify-center bg-white">
    <div class="w-full max-w-2xl flex flex-col bg-palegrey bg-opacity-30 sm:rounded-4xl rounded-2xl">

      <div class="px-8 sm:pt-8 pt-2" id="titleAbsolute">
        <div class="w-full flex mt-3">
          <a v-if="global.modeBZR" class="mx-auto pb-8" href="https://hypoteka.ownest.cz/" target="_blank">
            <img class="w-full max-w-[380px] xl:mx-10 mx-0" src="/img/BZR-Ownest.png" alt="Ownest - Z nájmu do vlastního"
                title="Ownest - Hlavní webové stránky"/>
          </a>
          <a v-else class="mx-auto" href="https://www.ownest.cz/" target="_blank">
            <img class="xl:h-20 h-12" src="/graphics/ownest-logo.svg" alt="Ownest - Z nájmu do vlastního"
                title="Ownest - Hlavní webové stránky"/>
          </a>
        </div>
      </div>

      <div class="w-full xl:pt-12 pt-4 xl:pb-8 pb-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import globalStore from '@/store.js';

export default {
  name: 'Layout',
  data() {
    return {
      global: globalStore(),
    }
  },
};
</script>