<template>
  <div class="p-4 text-white">
    <div class="pb-6 flex items-center justify-center flex-wrap">

      <template v-for="(cam, index) in cameras" :key="'cam-'+cam.deviceId">
        <ButtonBzr
          v-if="global.modeBZR"
          @click="change(cam.device.deviceId)" 
          :variant="deviceId === cam.device.deviceId ? 'secondary-md' : 'info-md'"
          class="mt-2 mr-2"
        >
          <template v-if="cam.label.front_cam">
            {{ cam.label.front_cam }} {{ front_cameras > 1 ? index + 1 : '' }}
          </template>
          <template v-else-if="cam.label.back_cam">
            {{ cam.label.back_cam }} {{ back_cameras > 1 ? index + 1 : '' }}
          </template>
          <template v-else>
            {{ cam.label.generic_cam }} {{ generic_cameras > 1 ? index + 1 : '' }}
          </template>
        </ButtonBzr>

        <Button
          v-else 
          @click="change(cam.device.deviceId)" 
          :variant="deviceId === cam.device.deviceId ? 'primary-md' : 'info-md'"
          class="mt-2 mr-2"
        >
          <template v-if="cam.label.front_cam">
            {{ cam.label.front_cam }} {{ front_cameras > 1 ? index + 1 : '' }}
          </template>
          <template v-else-if="cam.label.back_cam">
            {{ cam.label.back_cam }} {{ back_cameras > 1 ? index + 1 : '' }}
          </template>
          <template v-else>
            {{ cam.label.generic_cam }} {{ generic_cameras > 1 ? index + 1 : '' }}
          </template>
        </Button>
      </template>

    </div>

    <!-- <div class="flex justify-end">
      <Button @click="$emit('stop')" variant="danger-sm">
        Zastavit kameru
      </Button>
    </div> -->

  </div>
</template>

<script>
import globalStore from '@/store.js'
import Button from '@/components/forms/Button.vue'
import ButtonBzr from '@/components/forms-bzr/Button.vue'

export default {
  components: {
    Button,
    ButtonBzr
  },
  emits: ['change', 'stop'],
  props: {
    rememberDeviceTokenName: {
      type: String,
      default: 'slgr_scan_device_id'
    },
    cameras: {
      type: Array,
      default: () => []
    },
    deviceId: {
      type: String,
      default: null
    },
    front_cameras: {
      type: Number,
      default: 0
    },
    back_cameras: {
      type: Number,
      default: 0
    },
    generic_cameras: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      global: globalStore(),
    }
  },
  methods: {
    change(deviceId) {
      if(this.deviceId === deviceId)
        return

      localStorage.setItem(this.rememberDeviceTokenName, deviceId)
      // console.log('cam-pick', deviceId);
      this.$emit('change', deviceId)
    }
  }
}
</script>